import React, { useState } from 'react';
import { SliderContextConsumer } from './SliderContext'
import NumberFormat from 'react-number-format';
import data from '../data.js';
// import { useInView } from 'react-intersection-observer';

const LonResult = (props) => {


    // const { ref, inView, entry } = useInView({
    //     /* Optional options */
    //     threshold: 0.3,
    // });
    const [output, setOutput] = useState(0);
    const [dreamJob, setDreamJob] = useState(false);
    const [dreamJobResult, setDreamJobResult] = useState(0);
    const [THEdreamJob, setTHEdreamJob] = useState(1);
    const [increased, setincreased] = useState(0);

    const calculateResult_old = (lon, slidersVal) => {

        // reduce throws error if array is empty
        if (slidersVal.length > 0) {
            const sliders = slidersVal.map(el => el / 10)
            const lonLevel = Math.abs(sliders[0] - 10);
            const dreamJobFactor = sliders[sliders.length - 1]

            const B9 = lon + (((lonLevel - 5) / 25) * lon)

            sliders.shift();
            sliders.pop();
            const averageMid = (sliders.reduce((a, b) => (a + b)) / sliders.length) - 5; //B27

            const result = Math.round(B9 + ((averageMid / 50) * B9))

            const dreamJobCoefficient = 1.42

            const dreamFactorNEW = dreamJobFactor - 5;

            let dreamResult = 0;
            if (dreamJobFactor > 5) {
                dreamResult = Math.round(((100 - Math.pow(dreamFactorNEW, dreamJobCoefficient)) / 100) * result);
            }

            if (dreamJobFactor > 5) { setDreamJob(true) } else { setDreamJob(false) }
            if (dreamJobFactor > 9.5) { setTHEdreamJob(0) } else { setTHEdreamJob(1) }
            if (result > lon) { setincreased(0) } else { setincreased(1) }

            setOutput(result)
            setDreamJobResult(dreamResult)

        }
    }

    const calculateResult_new = (lon, slidersVal) => {

        // reduce throws error if array is empty
        if (slidersVal.length > 0) {
            const sliders = slidersVal.map(el => el / 10)
            const lonLevel = Math.abs(sliders[0] - 10);
            const dreamJobFactor = sliders[sliders.length - 1]
            const firstAverage = ((lonLevel + sliders[1]) / 2) - 5;

            const B9 = lon + ((firstAverage / 15) * lon)

            sliders.shift();
            sliders.shift();
            sliders.pop();

            const averageMid = (sliders.reduce((a, b) => (a + b)) / sliders.length) - 5; //B27

            const result = Math.round(B9 + ((averageMid / 50) * B9))

            const dreamJobCoefficient = 1.42
            const dreamFactorNEW = dreamJobFactor - 5;

            let dreamResult = 0;
            if (dreamJobFactor > 5) {
                dreamResult = Math.round(((100 - Math.pow(dreamFactorNEW, dreamJobCoefficient)) / 100) * result);
            }

            if (dreamJobFactor > 5) { setDreamJob(true) } else { setDreamJob(false) }
            if (dreamJobFactor > 9.5) { setTHEdreamJob(0) } else { setTHEdreamJob(1) }
            if (result > lon) { setincreased(0) } else { setincreased(1) }
            setOutput(result)
            setDreamJobResult(dreamResult)
        }
    }

    //SELECT AND COPY
    const handleClick = (e) => {
        const text = e.target.innerHTML;
        const node = e.target;

        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(node);
            range.select();
        } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(node);
            selection.removeAllRanges();
            selection.addRange(range);
        }
        const selection = window.getSelection();
        document.execCommand("Copy")
        selection.removeAllRanges();
        console.log("Copied!");
        node.innerHTML = "Copied!"
        setTimeout(() => { node.innerHTML = text }, 1000);

    }


    return (
        <div id="footer" className="active">
            <SliderContextConsumer>
                {context => {
                    const { lonInput, slidersInput } = context
                    if (!props.toggler) {
                        calculateResult_old(parseInt(lonInput), slidersInput)
                    } else {
                        calculateResult_new(parseInt(lonInput), slidersInput)
                    }
                    return (
                        <div>
                            <div id="resultcontainer">
                                <div className="stick">
                                    {output > 0 ?
                                        <p className={`resultText active} ${output > 0 ? "result" : "noResult"}`}>{!props.toggler ? data[2].eksisterendeJob.lon[increased] : data[2].nytJob.lon[increased]}</p>
                                        : ""}
                                    {/* <p>{props.inView.toString()}</p> */}
                                    <div id="stickyContainer" className="active">
                                        <NumberFormat
                                            onClick={handleClick}
                                            className="numbers"
                                            id="result"
                                            value={parseInt(output)}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={","}
                                            suffix={' kr.'}
                                        // thousandSeparator={'.'}
                                        />
                                    </div>

                                    {output > 0 ? <div id="dreamJob" className={dreamJob > 0 ? "active" : ""}>

                                        <p>{data[2].dreamJob[THEdreamJob]}</p>
                                        <NumberFormat
                                            onClick={handleClick}
                                            value={parseInt(dreamJobResult)}
                                            className="numbers"
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            suffix={' kr.'}
                                            decimalSeparator={","}
                                        />

                                    </div> : ""}
                                </div>
                                {output > 0 ?
                                    <div className={`text`}>
                                        <p className={`${output > 0 ? "result" : "noResult"}`}>{!props.toggler ? data[2].eksisterendeJob.opgave[increased] : data[2].nytJob.opgave[increased]}</p>
                                    </div> : ""}
                            </div>
                        </div>
                    )
                }}
            </SliderContextConsumer>
        </div>
    );
}

export default LonResult;