import React, { useState, useEffect } from 'react'

import { SliderContextConsumer } from './SliderContext'

function Slider(props) {
    const skala = [];
    props.data.skala.forEach((el, i) => {
        skala[i] = <li>{el}</li>
    });

    const [gradient, setgradient] = useState(`linear-gradient(90deg,transparent 0%, transparent 50%, transparent 80%,transparent 80.2%, transparent 100%)`);

    useEffect(() => {
        setgradient(`linear-gradient(90deg,transparent 0%, transparent 50%, transparent 80%,transparent 80.2%, transparent 100%)`);
    }, [props.toggler]);

    const style = {
        animationDelay: `${props.id * 50}ms`
    }
    return (
        <SliderContextConsumer>
            {context => {
                const { slidersInput, changeValue } = context;

                function onChangeHandler(i, val) {
                    changeValue(i, val)
                    if (val > 50) {
                        setgradient(`linear-gradient(90deg,transparent 0%, transparent 50%, rgba(241, 216, 169 ,${((val / 100) - 0.5) * 2}) ${val}%,transparent ${parseInt(val) + 2}%, transparent 100%)`)
                    }
                    if (val < 50) {
                        setgradient(`linear-gradient(90deg,transparent 0%,transparent ${parseInt(val) - 2}%,  rgba(241, 101, 104 ,${((val / -100) + 0.5) * 2}) ${val}%, transparent 50%,transparent 100%)`)

                    }
                }
                return (
                    <div className={"sliderContainer"} >

                        <div className="title" style={style}>
                            <p>{props.data.title}</p>
                            <small>{props.data.description}</small>
                        </div>
                        <div className="slider" style={style}>
                            <div className="skala">
                                <ul>
                                    {skala}
                                </ul>
                            </div>
                            <input
                                onChange={(e) => { onChangeHandler(props.id, e.target.value) }}
                                type="range"
                                min={0}
                                max={100}
                                value={slidersInput[props.id]}
                                style={slidersInput[props.id] > 50 ? { backgroundImage: gradient, backgroundPositionX: "-1rem" } : { backgroundImage: gradient, backgroundPositionX: "1rem" }}
                            />
                        </div>
                    </div>

                )
            }
            }
        </SliderContextConsumer>
    )
}

export default Slider