const data = [

    [ //eksisterende job

        {
            id: 1,
            title: "Eksisterende løn:",
            description: "Er det højt/lavt ift. andre som dig?",
            skala: ["Meget Lav", "Middel", "Meget højt"]
        },
        {
            id: 2,
            title: "Er du let at erstatte?",
            description: "Hvor svært at det at finde en ny dig med samme baggrund?",
            skala: ["Super let", "Middel", "Meget vanskelig"]
        },
        {
            id: 3,
            title: "Skaber du værdi?",
            description: "Hvordan performer du ift. andre?",
            skala: ["Langt under middel", "Middel", "Jeg outperformer alle"]
        },
        {
            id: 4,
            title: "Hjælper du teamet?",
            description: "Hvor meget bidrager du til andres succes?",
            skala: ["Ikke noget særligt", "Middel", "Uundværlig"]
        },
        {
            id: 5,
            title: "Udvikling ift. sidste lønforhandling?",
            description: "Ift. ansvar, resultater, faglighed etc.",
            skala: ["Stort set ingen", "Som forventet", "Ekstraordinært"]
        },
        {
            id: 6,
            title: "Er det dit drømmejob?",
            description: "Ift. organisation, ledelse, opgaver etc.",
            skala: ["Langt fra", "Middel", "Once in a life time"]
        },
    ],
    [ //nyt job
        {
            id: 1,
            title: "Nuværende lønniveau?",
            description: "Højt eller lavt, ift. kollegaer i samme rolle?",
            skala: ["Meget Lav", "Middel", "Meget højt"]
        },
        {
            id: 2,
            title: "Forventet ny lønramme?",
            description: "Ift. rolle, ansvar og branche, virksomhed",
            skala: ["Meget lavere", "Det samme", "Meget højere"]
        },
        {
            id: 3,
            title: "Kan man finde andre som dig?",
            description: "Hvor svært er det at rekruttere en med din baggrund?",
            skala: ["Super let", "Middel", "Meget vanskeligt"]
        },
        {
            id: 4,
            title: "Kvalifikationer ifht. job?",
            description: "Kompetencer, personlige egenskaber, erfaring etc.",
            skala: ["Tvivlsomme", "Middel", "Spot on"]
        },
        {
            id: 5,
            title: "Hvor hurtigt kan du skabe værdi?",
            description: "På basis af erfaring, branchekendskab etc.",
            skala: ["Der går lang tid", "Middel", "Med det samme"]
        },
        {
            id: 6,
            title: "Performance vs. erfaring",
            description: "Er du bedre end andre med lige så meget erfaring?",
            skala: ["Tværtimod", "Middel", "I udpræget grad"]
        },
        {
            id: 7,
            title: "Er det drømmejobbet?",
            description: "Branche, virksomhed, team, chef, ansvar, opgaver etc.",
            skala: ["Næh", "Hverken eller", "Once in a life time"]
        }
    ],
    {
        nytJob: {
            lon: [
                "Baseret på din egen vurdering burde der være plads til at forhandle en lønstigning. Vores bud er, at du kan forhandle dig frem til:",
                "Baseret på din egen vurdering er det tvivlsomt om du skal forvente en højere løn. Erfaringsmæssigt ligger dit lønniveau formodentlig omkring:"
            ],
            opgave: [
                "Hvor meget du kan forvente afhænger af en række faktorer - herunder villighed og mulighed på den anden side af bordet. Så forbered dig godt. Husk at se på ALLE dine muligheder. Mange ting af høj værdi er gratis at give. Fleksibilitet, sikkerhed. Få evt. puljet småbeløb til større enkeltbeløb. Vi kan hjælpe dig med overblik og muligheder.",
                "Men hey! Du kan SAGTENS forhandle dig til en masse andre ting som har høj værdi for dig og som er nemme (ofte gratis) at give. Fleksibilitet, sikkerhed, omrokering på udvalgte poster. Husk at forhandle HELE pakken. Der er masser af muligheder. Og vi kan hjælpe dig."
            ]

        },
        eksisterendeJob: {
            lon: [
                "Baseret på din egen vurdering burde der være grundlag for en lønstiging. Hvor meget du kan forvente afhænger af en række faktorer - herunder villighed og mulighed på den anden side af bordet.",
                "Baseret på din egen vurdering er det nok tvivlsomt, om du kan forvente en større lønstigning. Dit lønniveau ligger formodentlig omkring:"
            ],
            opgave: [
                "Din opgave er nu at forberede dig grundigt til din forhandling. Husk at forhandle HELE pakken - økonomi, fleksibilitet, sikkerhed. Der er masser af muligheder som har værdi for dig, og som er nemme at give. Man kan ALTID forhandle en bedre samlet pakke. Vi kan hjælpe dig.",
                "Men hey! Der er stadig MASSER af mulighed for at forhandle ting hjem, der har høj værdi for dig og som er nemt at give. Fleksibilitet, sikkerhed - måske en omrokering på nogle beløb du allerede får. Man kan ALTID forhandle en bedre samlet pakke. Vi kan hjælpe dig."
            ]
        },
        dreamJob: [
            "Fordi det her er DRØMMEJOBBET er du måske helt tilfreds med:",
            "Fordi det i en vis udstrækning er dit drømmejob er du måske helt tilfreds med:",
            ""
        ]
    }

]

export default data;