import React, { useState, useEffect } from "react"
import data from '../data'


const { Provider, Consumer } = React.createContext()

function SliderContextProvider(props) {

    const [lonInput, setLonInput] = useState(0);
    const [slidersInput, setSlidersInput] = useState([])
    const [dataSet, setDataSet] = useState(data[0]);

    function changeValue(index, value) {
        // console.log(slidersInput, value)
        setSlidersInput(() => {
            let array = [...slidersInput];
            array[index] = parseInt(value);
            return (array);

        })
    }

    //when switching dataset
    useEffect(() => {
        let array = []
        dataSet.forEach((el, index) => {
            array[index] = 50
        })
        setSlidersInput(array);
    }, [dataSet])

    function switchDataSet(val) {
        setDataSet(val ? data[1] : data[0])
    }




    return (
        <Provider value={
            {
                slidersInput: slidersInput,
                lonInput: lonInput,
                setLonInput: setLonInput,
                changeValue: changeValue,
                switchDataSet: switchDataSet
            }
        }>
            {props.children}
        </Provider>
    )
}

export { SliderContextProvider, Consumer as SliderContextConsumer }