import './scss/App.scss';
import React, { useState, useEffect } from 'react'
import Slider from './components/Slider'
import data from './data'
import Switch from 'react-switch'
import SalaryInput from "./components/salaryInput";
import LonResult from './components/LonResult';
// import NewJobResult from './components/NewJobResult';
import { SliderContextConsumer } from './components/SliderContext'


function App() {
  // const [sliderValue, setSlider] = useState(50)
  const [toggler, setToggler] = useState(false);
  const [slidersComps, setSlidersComps] = useState([]);
  const [changeMode, setChangeMode] = useState("loaded");


  function handleToggler(e) {
    setToggler(e)
  }
  // console.log(toggler);

  useEffect(() => {
    const newSlidersComps = []
    data[toggler ? 1 : 0].forEach((el, i) => {
      console.log(el);
      newSlidersComps[i] = <Slider key={i} id={i} data={el} toggler={toggler} />
    })
    setSlidersComps(newSlidersComps);
    setChangeMode("loading")
    setTimeout(() => { setChangeMode("loaded") }, 1050)
    // console.log(slidersComps)
  }, [toggler])


  // function handleSlider(e) {
  //   setSlider(e.target.value);
  // }
  // console.log(data);
  return (

    <div id="appContainer">
      <div id="header">
        {/* <div id="logo">
          <img src="/img/logo_white_no_txt.png" width="40" height="40" alt="paretify" className={toggler ? "active" : " "}></img>
          <h1>paretify</h1>
        </div>
        <h1>HVILKEN LØN SKAL JEG FORHANDLE OM?</h1> */}
        <div id="switch">
          <h2 className={!toggler ? "active" : ""}>Eksisterende job</h2>
          <SliderContextConsumer>
            {context => (
              <Switch
                onChange={handleToggler}
                onLoad={context.switchDataSet(toggler)}
                onColor="#ffffff"
                // onColor="#C7D3D5"
                offColor="#ffffff"
                // offColor="#C7D3D5"
                className="switch"
                offHandleColor="#E89397"
                onHandleColor="#E89397"
                activeBoxShadow='0 0 2px 3px #A8DADD'
                // onHandleColor
                // width="100%"
                checked={toggler}
                uncheckedIcon={false}
                checkedIcon={false} />
            )
            }
          </SliderContextConsumer>
          <h2 className={toggler ? "active" : ""}>Nyt job</h2>
        </div>

        <SalaryInput />
      </div>
      <div id="allSliders" className={changeMode}>
        {slidersComps}
      </div>

      {/* {!toggler ? <ExistingLonResult /> : <NewJobResult />} */}
      <LonResult toggler={toggler} />
    </div>

  );
}

export default App;

